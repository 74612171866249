


























































































































































































































import { IAddress } from '@/interfaces/address';
import { IClient } from '@/interfaces/client';
import Base from '@/mixins/Base.vue';

const component = Base.extend({
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },
    data() {
        return {
            isEditAddressModalActive: false,
            isAddAddressModalActive: false,
            selectedAddressType: '',
            selectedAddressIndex: false,
            newAddress: {} as IAddress,
            addressesTypes: [
                {
                    type: 'location',
                    title: 'Location',
                },
                {
                    type: 'billing',
                    title: 'Billing',
                },
            ],
        };
    },
    methods: {
        filterAddressByType(type: string): Array<any> {
            if (this.client.addresses) {
                this.client.addresses.forEach((c: IAddress, index) => {
                    const address = c;
                    address.index = index;
                });
                const addresses = this.client.addresses.filter((c) => c.type === type);

                return addresses;
            }
            return [];
        },
        addAddress(): void {
            this.newAddress.type = this.selectedAddressType;

            const count = this.client.addresses.filter((a) => a.type === this.selectedAddressType).length;

            this.newAddress.client_address = {
                is_default: count === 0,
            };
            if (!this.client.addresses) this.client.addresses = [];
            this.client.addresses.push(JSON.parse(JSON.stringify(this.newAddress)));

            this.selectedAddressType = '';
            this.isAddAddressModalActive = false;
        },
        deleteAddress(index: number): void {
            this.confirm().then(() => {
                this.$delete(this.client.addresses || [], index);
            });
        },
        makeDefaultAddress(type: string, index: number): void {
            this.client.addresses.forEach((a: IAddress, i: number) => {
                const address = a;

                if (address.type === type && address.client_address) {
                    this.$set(this.client.addresses[i].client_address, 'is_default', index === i);
                }
            });
        },
    },
});

export default component;
