




















































































































































































































































































































































































import Base from '@/mixins/Base.vue';
import { IClient, IClientResponse } from '@/interfaces/client';

const component = Base.extend({
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
        picture: {
            type: Boolean,
            default: true,
        },
    },
    methods: {

        uploadClientPicture(): void {
            const formData = new FormData();

            if (this.client.picture) {
                formData.append('picture', this.client.picture as any);
            }

            this.post<IClientResponse>(`clients/${this.$route.params.id}/picture`, formData)
                .then(({ data }) => {
                    this.client.picture_url = data.data.picture_url;
                    this.$emit('success', { message: 'picture updated' });
                })
                .finally(() => {
                    this.client.picture = undefined;
                });
        },
        cloneAddress(): void {
            this.$set(this.client, 'billing_phone', this.client.phone);
            this.$set(this.client, 'billing_email', this.client.email);
        },
    },
});

export default component;
