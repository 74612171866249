var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block"},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Billing settings ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"exclude_automatic_billing","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Automatic billing","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-checkbox',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.exclude_automatic_billing),callback:function ($$v) {_vm.$set(_vm.client, "exclude_automatic_billing", $$v)},expression:"client.exclude_automatic_billing"}},[_vm._v(" Exclude from automatic billing ")])],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"post_fee","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Posted invoices","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-checkbox',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.post_fee),callback:function ($$v) {_vm.$set(_vm.client, "post_fee", $$v)},expression:"client.post_fee"}},[_vm._v(" Add postage for invoices sent by post (3.00 €) ")])],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"invoice_due_date","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Due days of the invoice","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('b-field',[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.client.invoice_due_date),callback:function ($$v) {_vm.$set(_vm.client, "invoice_due_date", $$v)},expression:"client.invoice_due_date"}}),_c('div',{staticClass:"button is-static"},[_vm._v(" days ")])],1)],1),_c('div',{staticClass:"column"},[_c('b-field',[_c('div',{staticClass:"m-2 has-text-grey-light"},[_vm._v(" since ")]),_c('div',{staticClass:"is-flex is-flex-direction-row"},[_c('b-radio',{attrs:{"disabled":_vm.isLoading,"native-value":"invoice_created"},model:{value:(_vm.client.invoice_due_relative_to),callback:function ($$v) {_vm.$set(_vm.client, "invoice_due_relative_to", $$v)},expression:"client.invoice_due_relative_to"}},[_vm._v(" the invoice date ")]),_c('b-radio',{attrs:{"disabled":_vm.isLoading,"native-value":"end_of_month"},model:{value:(_vm.client.invoice_due_relative_to),callback:function ($$v) {_vm.$set(_vm.client, "invoice_due_relative_to", $$v)},expression:"client.invoice_due_relative_to"}},[_vm._v(" the end of the month ")])],1)])],1)])])]}}])})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Tax ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"tax_rate","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Tax rate","horizontal":"","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('div',[_c('b-radio',{attrs:{"native-value":"0.21","disabled":_vm.isLoading},model:{value:(_vm.client.tax_rate),callback:function ($$v) {_vm.$set(_vm.client, "tax_rate", $$v)},expression:"client.tax_rate"}},[_vm._v(" 21% ")]),_c('b-radio',{attrs:{"native-value":"0.06","disabled":_vm.isLoading},model:{value:(_vm.client.tax_rate),callback:function ($$v) {_vm.$set(_vm.client, "tax_rate", $$v)},expression:"client.tax_rate"}},[_vm._v(" 6% ")]),_c('b-radio',{attrs:{"native-value":"0","disabled":_vm.isLoading},model:{value:(_vm.client.tax_rate),callback:function ($$v) {_vm.$set(_vm.client, "tax_rate", $$v)},expression:"client.tax_rate"}},[_vm._v(" 0% ")])],1)])]}}])}),(_vm.client.tax_rate == 0)?[_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"tax_type","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Tax type","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('div',[_c('b-radio',{attrs:{"native-value":"0","disabled":_vm.isLoading},model:{value:(_vm.client.tax_type),callback:function ($$v) {_vm.$set(_vm.client, "tax_type", $$v)},expression:"client.tax_type"}},[_vm._v(" Intra-community ")]),_c('b-radio',{attrs:{"native-value":"1","disabled":_vm.isLoading},model:{value:(_vm.client.tax_type),callback:function ($$v) {_vm.$set(_vm.client, "tax_type", $$v)},expression:"client.tax_type"}},[_vm._v(" Co-contractor ")])],1)])]}}],null,false,1485273128)})]:_vm._e()],2),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Transport ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"transport_cost","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Transport costs per km (from 16 km)","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-field',[_c('b-input',{attrs:{"disabled":_vm.isLoading,"type":"number","step":"1","min":"0","expanded":""},model:{value:(_vm.client.transport_cost),callback:function ($$v) {_vm.$set(_vm.client, "transport_cost", $$v)},expression:"client.transport_cost"}}),_c('div',{staticClass:"button is-static"},[_vm._v(" € ")])],1)],1)]}}])})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Purchase orders email ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"email_purchase_order","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"E-mail","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null,"expanded":""}},[_c('b-input',{attrs:{"type":"email","disabled":_vm.isLoading},model:{value:(_vm.client.email_purchase_order),callback:function ($$v) {_vm.$set(_vm.client, "email_purchase_order", $$v)},expression:"client.email_purchase_order"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"project_po_order","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Project PO Number","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null,"expanded":""}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.project_po_order),callback:function ($$v) {_vm.$set(_vm.client, "project_po_order", $$v)},expression:"client.project_po_order"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"project_po_order_expiry","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Project PO Expires","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null,"expanded":""}},[_c('b-datepicker',{attrs:{"value":_vm.client.project_po_order_expiry,"editable":"","disabled":_vm.isLoading}})],1)]}}])})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" E-mails ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"invoice_full_payment_confirmation","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Confirmation","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-checkbox',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.invoice_full_payment_confirmation),callback:function ($$v) {_vm.$set(_vm.client, "invoice_full_payment_confirmation", $$v)},expression:"client.invoice_full_payment_confirmation"}},[_vm._v(" full invoice payment confirmation email ")])],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"halfterm_payment_reminder","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Reminder","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-checkbox',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.halfterm_payment_reminder),callback:function ($$v) {_vm.$set(_vm.client, "halfterm_payment_reminder", $$v)},expression:"client.halfterm_payment_reminder"}},[_vm._v(" Send a mid-term payment reminder email ")])],1)]}}])}),_c('b-field',{attrs:{"horizontal":"","label":"Include PDF & XLS","type":{'is-danger': _vm.errors[0]},"message":_vm.errors ? _vm.errors[0] : null}},[_c('div',[_c('p',{staticClass:"mb-2"},[_vm._v(" Include PDF and XLS files of assignments in emails : ")]),_c('b-field',{attrs:{"grouped":""}},[_c('b-checkbox',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.job_emails),callback:function ($$v) {_vm.$set(_vm.client, "job_emails", $$v)},expression:"client.job_emails"}},[_vm._v(" Job emails ")]),_c('b-checkbox',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.invoice_emails),callback:function ($$v) {_vm.$set(_vm.client, "invoice_emails", $$v)},expression:"client.invoice_emails"}},[_vm._v(" Invoice emails ")])],1)],1)]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"invoice_extra_info_mandatory","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Extra info","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-checkbox',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.invoice_extra_info_mandatory),callback:function ($$v) {_vm.$set(_vm.client, "invoice_extra_info_mandatory", $$v)},expression:"client.invoice_extra_info_mandatory"}},[_vm._v(" Invoice extra info mandatory ")])],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"price_override","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Services price override","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-checkbox',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.price_override),callback:function ($$v) {_vm.$set(_vm.client, "price_override", $$v)},expression:"client.price_override"}},[_vm._v(" Allow service price override ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }