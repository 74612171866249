var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block"},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" General ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"name","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Name","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"client_type_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Type","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-client-type',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.client.client_type),callback:function ($$v) {_vm.$set(_vm.client, "client_type", $$v)},expression:"client.client_type"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"sources","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Sources","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-source',{attrs:{"multiple":true,"disabled":_vm.isLoading},model:{value:(_vm.client.sources),callback:function ($$v) {_vm.$set(_vm.client, "sources", $$v)},expression:"client.sources"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"","name":"parent_client_id","tag":"b-field"}},[_c('b-field',{attrs:{"horizontal":"","label":"Parent client","type":{'is-danger': _vm.errors[0]},"message":_vm.errors ? _vm.errors[0] : null}},[_c('sp-client',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.client.parent_client),callback:function ($$v) {_vm.$set(_vm.client, "parent_client", $$v)},expression:"client.parent_client"}})],1)],1),(_vm.picture)?_c('b-field',{attrs:{"horizontal":"","label":"Picture"}},[_c('div',{staticClass:"media"},[(_vm.client.picture_url)?_c('div',{staticClass:"media-left"},[_c('figure',{staticClass:"image is-128x128",style:({height: 'auto'})},[_c('img',{attrs:{"src":_vm.client.picture_url,"alt":""}})])]):_vm._e(),_c('div',{staticClass:"media-content"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"picture","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-upload',{staticClass:"file-label",attrs:{"disabled":_vm.isLoading},on:{"input":_vm.uploadClientPicture},model:{value:(_vm.client.picture),callback:function ($$v) {_vm.$set(_vm.client, "picture", $$v)},expression:"client.picture"}},[_c('div',{staticClass:"button",attrs:{"disabled":_vm.isLoading}},[_c('b-icon',{attrs:{"icon":"upload"}}),_c('span',[_vm._v("Upload file")]),(_vm.client.picture)?_c('span',{staticClass:"tag ml-2"},[_vm._v(" "+_vm._s(_vm.client.picture.name)+" ")]):_vm._e()],1)]),(_vm.client.picture_url)?_c('b-button',{on:{"click":function($event){_vm.client.picture_url = null}}},[_vm._v(" Remove ")]):_vm._e()],1)]}}],null,false,2697926175)})],1)])]):_vm._e(),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"language_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Language","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-language',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.client.language),callback:function ($$v) {_vm.$set(_vm.client, "language", $$v)},expression:"client.language"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"","name":"active","tag":"b-field"}},[_c('b-field',{attrs:{"horizontal":"","label":"Active","type":{'is-danger': _vm.errors[0]},"message":_vm.errors ? _vm.errors[0] : null}},[_c('b-switch',{model:{value:(_vm.client.active),callback:function ($$v) {_vm.$set(_vm.client, "active", $$v)},expression:"client.active"}})],1)],1)],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Address & contact informations ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"email","name":"email","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"E-mail","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"type":"email","disabled":_vm.isLoading},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"phone","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Phone","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.phone),callback:function ($$v) {_vm.$set(_vm.client, "phone", $$v)},expression:"client.phone"}})],1)]}}])})],1),_c('div',{staticClass:"block"},[_c('b-button',{staticClass:"is-pulled-right",attrs:{"size":"is-small","disabled":_vm.isLoading,"icon-left":"clone","type":"is-primary","outlined":""},on:{"click":function($event){return _vm.cloneAddress()}}},[_vm._v(" Copy from general ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" Billing informations ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"email","name":"billing_email","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"E-mail","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"type":"email","disabled":_vm.isLoading},model:{value:(_vm.client.billing_email),callback:function ($$v) {_vm.$set(_vm.client, "billing_email", $$v)},expression:"client.billing_email"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"billing_phone","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Phone","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.billing_phone),callback:function ($$v) {_vm.$set(_vm.client, "billing_phone", $$v)},expression:"client.billing_phone"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"private_professional","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Type","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-select',{attrs:{"expanded":"","disabled":_vm.isLoading},model:{value:(_vm.client.private_professional),callback:function ($$v) {_vm.$set(_vm.client, "private_professional", $$v)},expression:"client.private_professional"}},[_c('option',{attrs:{"value":"professional"}},[_vm._v(" Professional ")]),_c('option',{attrs:{"value":"private"}},[_vm._v(" Private / without VAT ")])])],1)]}}])}),_c('sp-has-permission',{attrs:{"name":"clients.vat.update"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required_if:private_professional,professional|vat","name":"vat","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"V.A.T","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"placeholder":"BE0123456789","disabled":_vm.isLoading || _vm.client.private_professional !== 'professional'},model:{value:(_vm.client.vat),callback:function ($$v) {_vm.$set(_vm.client, "vat", $$v)},expression:"client.vat"}})],1)]}}])})],1)],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Extras ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"extra_infos","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Extras","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"type":"textarea","disabled":_vm.isLoading},model:{value:(_vm.client.extra_infos),callback:function ($$v) {_vm.$set(_vm.client, "extra_infos", $$v)},expression:"client.extra_infos"}})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }