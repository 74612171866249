






































































































































































































































































































































































































































































































import { IClient } from '@/interfaces/client';
import { ICountry } from '@/interfaces/country';
import { IService } from '@/interfaces/service';
import { IRegion } from '@/interfaces/region';
import Base from '@/mixins/Base.vue';

const component: any = Base.extend({
    name: 'Prices',
    components: {
        Prices: () => import('./Prices.vue'),
    },
    props: {
        client: {
            type: Object as () => (IClient),
        },
        service: {
            type: Object as () => (IService),
        },
        prices: {
            type: Array,
        },

    },

    data() {
        return {
            isEditingRegion: false,
            isAddZoneModalActive: false,
            activeRegion: 'regions.0',
            newZone: {
                country: undefined as any|ICountry,
                region: undefined as any|IRegion,
            },
        };
    },
    methods: {
        onStepChange(value: string) {
            if (value === 'addZone') {
                this.isAddZoneModalActive = true;
            }
        },
        removeZone(region_id: number): void {
            this.confirm().then(() => {
                this.prices.forEach(() => {
                    const index = this.prices.findIndex((p: any) => p.region_id === region_id);
                    this.$delete(this.prices, index);
                });

                this.activeRegion = 'regions.0';
            });
        },
        addZone() {
            if (this.newZone.region && this.prices.findIndex((z: any) => z.region && z.region.id === this.newZone.region.id) === -1) {
                const baseZone = JSON.parse(JSON.stringify(this.prices.find((p: any) => !p.region_id)));

                baseZone.price.id = null;
                baseZone.children.forEach((c: any) => {
                    const child = c;
                    child.price.id = null;
                    child.units.forEach((u: any) => {
                        const unit = u;
                        unit.price.id = null;
                    });
                });

                baseZone.units.forEach((u: any) => {
                    const unit = u;
                    unit.price.id = null;
                });

                this.prices.push({
                    ...baseZone,
                    region_id: this.newZone.region.id,
                    region: this.newZone.region,
                    country_id: this.newZone.country.id,
                    country: this.newZone.country,
                });
            }
            this.isAddZoneModalActive = false;
            this.activeRegion = 'regions.0';

            this.newZone.region = undefined;
            this.newZone.country = undefined;
        },
        updateCountry(z: any, event: any): void {
            const region = z;
            if (event.id) {
                region.country = event;
                this.$forceUpdate();
            }
        },
        updateZone(z: any, event: any): void {
            const region = z;
            this.prices.forEach((p: any) => {
                const price = p;
                if (event.id && price.region_id === region.region.id) {
                    price.region_id = event.id;
                    price.country_id = event.country_id;
                }
            });

            if (event.id) region.region = event;
        },
    },
});

export default component;
