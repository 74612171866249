














































































































































































































import Base from '@/mixins/Base.vue';
import { IClient } from '@/interfaces/client';
import { IContact, IContactType } from '@/interfaces/contact';

const component = Base.extend({
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },

    data() {
        return {
            contactsTypes: [
                {
                    title: 'Email inspection date',
                    type: 'planning',
                    emailOnly: true,
                },
                {
                    title: 'Email tour guide inspector (no automatic e-mail)',
                    type: 'acclienting',
                    emailOnly: false,
                },
                {
                    title: 'Email reports',
                    type: 'report',
                    emailOnly: true,
                },
                {
                    title: 'Email invoice',
                    type: 'invoice',
                    emailOnly: true,
                },
                {
                    title: 'Email accounting and debtor (no automatic e-mail)',
                    type: 'accounts',
                    emailOnly: false,
                },
                {
                    title: 'Email order form commission',
                    type: 'order',
                    emailOnly: true,
                },
            ] as Array<IContactType>,
        };
    },
    methods: {
        filterContactByType(type: string): Array<any> {
            if (this.client.contacts) {
                this.client.contacts.forEach((c: IContact, index) => {
                    const contact = c;
                    contact.index = index;
                });
                const contacts = this.client.contacts.filter((c) => c.type === type);

                return contacts;
            }
            return [];
        },
        addContactByType(type: string): void {
            const contact: IContact = {
                type,
                id: undefined,
                name: '',
                email: '',
                phone: '',
            } as IContact;

            if (!this.client.contacts) this.client.contacts = [];
            this.client.contacts.push(contact);
        },
        cloneContactByType(type: string, index: string|number): void {
            if (!this.client.contacts) this.client.contacts = [];

            this.$set(this.client.contacts, index, {
                type,
                email: (this.client.email || this.client.billing_email),
                phone: (this.client.phone || this.client.billing_phone),
            });
        },
        deleteContact(index: number): void {
            this.confirm().then(() => {
                this.$delete(this.client.contacts || [], index);
            });
        },
    },
});

export default component;
