













































































































































































































































































































































































































import moment from 'moment';

import { IClient } from '@/interfaces/client';
import Base from '@/mixins/Base.vue';

export default Base.extend({
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },
    data() {
        return {
            moment,
        };
    },
    watch: {
        active() {

        },
    },
});
